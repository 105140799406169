import {AppBar, Toolbar, Container, Button} from '@mui/material';
import {
    Link as RouterLink
} from "react-router-dom";

function Appbar({toggleDrawer}) {
return (
  <AppBar position='sticky' color='appBarBlack'>
    <Container>
      <Toolbar variant='dense' disableGutters>
        <Button sx={{ my: 2, display: 'block' }} component={RouterLink} to='/'>
         Home
        </Button>
        <Button sx={{ my: 2, display: 'block' }} component={RouterLink} to='/about'>
          About
        </Button>
      </Toolbar>
    </Container>
  </AppBar>
);
}
export default Appbar;
