import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material';
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const alephTheme = {
  palette: {
    primary: {
      main: '#be1f24',
    },
    secondary: {
      main: '#f15a22',
    },
    info: {
      main: '#5f8ac7',
    },
    success: {
      main: '#94c9a9',
    },
    background: {
      default: '#303030',
    },
    text: {
      primary: '#d5d5d5',
    }
  },
}
const whateverRemainsTheme = {
  palette: {
    mode: 'dark',
    appBarBlack: {
      main: '#000'
    },
    appBarWhite: {
      main: '#fff'
    }
  },
  typography: {
    h1: {
      fontSize: '3rem',
      padding: 12,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '2rem',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '2rem',
      textTransform: 'uppercase',
      padding: "20px 0"
    },
  },

  components: {
    MuiLink: {
      defaultProps: {
        underline: 'none'
      }
    }
  }
}
const themeProps = deepmerge(alephTheme, whateverRemainsTheme)
const theme = createTheme(themeProps);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
