import {Typography, Box, Grid, Link} from '@mui/material';
import the_pitch from "./img/story-1-page-04_orig.png";
import forward from "./img/story-2-page-10_orig.png";
import new_broom from "./img/story-3-page-23_1_orig.png";
import the_horror from "./img/story-4-page-26_2_orig.png";
import ppt from "./20190404_that_which_remains_full_story.ppsx";
import {
    Link as RouterLink
} from "react-router-dom";

const cards = [
  {
    href:'the-pitch',
    src:the_pitch,
    title:"1. The Pitch"
  },
  {
    href:'forward',
    src:forward,
    title:"2a. Forward"
  },
  {
    href:'new-broom',
    src:new_broom,
    title:"2b. New Broom"
  },
  {
    href:'the-horror',
    src:the_horror,
    title:"3. The Horror",
  }
]

function Card({href, src, title}) {
  return (
    <Grid item xs={12} sm={6}>
      <Link to={href} component={RouterLink}> <Box component="img" sx={{maxWidth:'100%'}} src={src} alt={title}/> </Link>
      <Typography variant="h2">{title}</Typography>
    </Grid>
  );
}

function Home() {
return (
<>
<Typography variant="h1">Whatever Remains</Typography>

<Box sx={{marginBottom: 3}}>
This series of interactive graphic stories is designed to explore themes
related to cyber resilience and defence procurement. The work was funded
by <Link 
  href="https://www.gov.uk/government/organisations/defence-science-and-technology-laboratory"
  target="_blank"
  rel="noreferrer"
>
  Dstl
</Link>
, an executive agency of the <Link
  href="https://www.gov.uk/government/organisations/ministry-of-defence"
  target="_blank"
  rel="noreferrer">
  UK Ministry of Defence
</Link> (UK MOD). The
narratives herein are a work of fiction. They were developed in their entirety
by <Link 
  href="https://www.alephinsights.com/"
  target="_blank"
  rel="noreferrer"
>Aleph Insights</Link> and <Link href="about">their associates</Link>.
They should not be interpreted as representing the views of the
UK MOD or as reflecting any current or future UK MOD policy.
</Box>

<Grid container spacing={4}>
  {cards.map((card, i) => {
    return <Card title={card.title} src={card.src} href={card.href} key={i}/>
  })}
</Grid>

<Typography variant='body' paragraph sx={{mt:4}}>If you have problems accessing the web versions of the stories above, you can <Link title="Download file: 20190404_that_which_remains_full_story.ppsx" href={ppt}>download the full set here instead</Link>.</Typography>

</>

);
}

export default Home;
