import "./App.css";
import Home from "./Home.js";
import About from "./About.js";
import Page from "./Page.js";
import Appbar from "./Appbar.js";
import NotFound from "./NotFound.js";
import { Container } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const code = {
  "the-pitch":
    "2PACX-1vQ70jz7Iv4j5ax-FzSy7CkcXOpCKp9-qdYzF3GQTjQ7sGRSYFTMB6n6tDRRMCrqmlybeLzLasNEdHPY",
  forward:
    "2PACX-1vSoEJ4WIW0nnf0P-SHAZzI2ggNBCt9-KnLYQQbLgATKkQDc69nd5Y5imeMbukcYm96KKRnG2jNcZeKi",
  "new-broom":
    "2PACX-1vT7i7BDJWex1yifrosi3Gld1nLtoKOfPmNX6b2ks0Ajmu4JN1l3MtKXUqKFECrcB5vMuNUvl1joVZLY",
  "the-horror":
    "2PACX-1vQlnDjVAK3crCBxJwdsO5_7H6zzoaFD3ClMxGfCI5BdhpLUiaCx00qoLe5bazKMf_aBPaazmUGABD-8",
};

function App() {
  return (
    <Router>
      <Appbar />
      <Container>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/about" element={<About />}/>
          <Route
            path="/the-pitch"
            element={<Page title="The Pitch" code={code["the-pitch"]} />}
          />
          <Route
            path="/forward"
            element={<Page title="Forward" code={code["forward"]} />}
          />
          <Route
            path="/new-broom"
            element={<Page title="New Broom" code={code["new-broom"]} />}
          />
          <Route
            path="/the-horror"
            element={<Page title="The Horror" code={code["the-horror"]} />}
          />
          <Route
            path="*"
            element={<NotFound />}
          />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
