import {Typography} from '@mui/material';
import {Box} from '@mui/material';

import logo from "./img/ai-logo-roundel-only-very-large_orig.png";

function Page({title, code}) {
return (
  <>
    <Typography variant='h3' >{title}</Typography>
    <iframe style={{margin:'20px auto', display:'block'}}
      title={title}
      src={`https://docs.google.com/presentation/d/e/${code}/embed?start=false&loop=false&delayms=6000000&rm=minimal`}
      frameBorder="0"
      width="960"
      height="749"
      allowFullScreen={true}
      >
    </iframe>
    <Box sx={{width:'30px', float:'right', mx:2}}component='img' src={logo}/>
  </>

);
}

export default Page;

