import {Typography, Link, Grid} from '@mui/material';
import logo from "./img/ai-logo-roundel-only-very-large_orig.png";

function NotFound() {
  return (
    <>
      <Typography variant="h3">404</Typography>

      <Grid container>
        <Grid item  xs={12} sm={6}>
          <Typography>Page not found.</Typography>
        </Grid>
        <Grid item  xs={12} sm={6}>
          <Link href='https://www.alephinsights.com' target='_blank' rel="noreferrer">
            <img style={{maxWidth:'100%'}} src={logo} alt="Logo" />
          </Link>
        </Grid>
      </Grid>
    </>
  );

}

export default NotFound;
