import {Typography, Link, Grid} from '@mui/material';
import logo from "./img/ai-logo-roundel-only-very-large_orig.png";


function About() {
  return (
    <>
      <Typography variant="h3">THE TEAM</Typography>

      <Grid container>
        <Grid item  xs={12} sm={6}>
          <Typography>These stories were produced by <Link to="https://www.alephinsights.com" target="_blank" rel="noreferrer">Aleph Insights</Link>, an analytical consultancy which specialises in risk, data and cyber analysis. The stories were authored by <Link to="https://www.adamroberts.com/" target="_blank" rel="noreferrer">Adam Roberts</Link> and illustrated by <Link to="https://frenchbird.wordpress.com/" target="_blank" rel="noreferrer">Sarah Ford</Link> and <Link to="http://www.serknowles.com/about.html" target="_blank" rel="noreferrer">Sophie Knowles</Link>.</Typography>

        </Grid>
        <Grid item  xs={12} sm={6}>
          <Link href='https://www.alephinsights.com' target='_blank' rel="noreferrer">
            <img style={{maxWidth:'100%'}} src={logo} alt="Logo" />
          </Link>
        </Grid>
      </Grid>
    </>
  );

}

export default About;
